<template>
  <div class="single blog page">
    <section id="banner" class="align-middle">
      <banner-image-overlay></banner-image-overlay>
      <div class="post-details container">
        <div v-show="error" class="row">
          <div class="col">
            <div class="alert alert-danger" role="alert">
              {{ error }}
            </div>
          </div>
        </div>
        <div v-show="success" class="row">
          <div class="col">
            <div class="alert alert-success" role="success">
              {{ success }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input v-model="post.title" type="text" placeholder="Post title"/>
          </div>
        </div>
        <div class="row">
          <div class="col small-banner">
            <img v-show="post.small_banner" :src="post.small_banner" width="90" height="64"/>
            <input @change="onSmallBannerChange" type="file" accept="image/jpeg">
            <label class="text-white"><i>*Small banner has to have 685w by 486h pixels</i></label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <textarea v-model="post.content_overview" placeholder="Text appeared right bellow image banner"></textarea>
          </div>
        </div>
        <div class="row">
          <div class="col actions">
            <button
              class="button small delete"
              @click="deletePost">Delete</button>
            <button
              class="button special small save"
              @click="savePost('public')">Save and publish</button>
            <button
              v-show="post.status !== 'public'"
              class="button small save save-as-draft"
              @click="savePost('draft')">Save as a draft</button>
          </div>
        </div>
      </div>
    </section>
    <div class="container 75%">
      <section class="row 200%">
        <div class="post-content">
          <div id="editorjs"></div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import BannerImageOverlay from '../../../components/common/utils/BannerImageOverlay.vue';
import EditorJS from '@editorjs/editorjs';
import BlogPostsApi from '../../../api/BlogPosts';
import Header from '@editorjs/header';
import Underline from '@editorjs/underline';
import List from '@editorjs/list';
import Delimiter from '@editorjs/delimiter';
import Embed from '@editorjs/embed';
import ImageTool from '@editorjs/image';
import AttachesTool from '@editorjs/attaches';

export default {
  name: 'EditBlogPost',
  components: {
    BannerImageOverlay,
  },
  data() {
    return {
      editor: null,
      post: {
        id: 0,
        title: '',
        content: {},
        status: 'draft',
        permalink: '',
        content_overview: '',
        small_banner: undefined,
      },
      file: undefined,
      error: false,
      success: false,
    };
  },
  methods: {
    onSmallBannerChange(event) {
      this.file = event.target.files[0];
      this.post.small_banner = URL.createObjectURL(this.file);
    },
    getBlogPost(permalink) {
      BlogPostsApi
        .get({permalink})
        .then(({ data }) => {
          this.post = data;
          this.initEditorJS();
        })
        .catch((error) => {
          this.error = this.parseError(error, 'Unable to retrieve blog post');
        });
    },
    savePost(status) {
      this.success = false;
      this.error = false;
      this.post.status = status;
      this.editor.save()
        .then((content) => {
          this.post.content = content;
          this.post.id === 0 ? this.create() : this.update();
        }).catch((error) => {
          console.error(error);
          this.parseError(error, 'Unable to save blog editor content');
        });
    },
    create() {
      BlogPostsApi
        .create(this.post)
        .then(({data}) => {
          this.post = data;
          this.success = this.post.status === 'draft' ? 'Draft saved with success' : 'Post published with success';
          if (this.file) {
            this.saveBanner()
              .then(() => { this.$router.push(`${this.post.permalink}`); });
            return;
          }
          this.$router.push(`${this.post.permalink}`);
        })
        .catch((error) => {
          this.error = this.parseError(error, 'Unable to create blog post');
        });
    },
    update() {
      BlogPostsApi
        .update(this.post)
        .then(({data}) => {
          this.post = data;
          this.success = this.post.status === 'draft' ? 'Draft updated with success' : 'Post updated with success';
          if (this.file) {
            this.saveBanner();
          }
        })
        .catch((error) => {
          this.error = this.parseError(error, 'Unable to update blog post');
        });
    },
    deletePost() {
      this.success = false;
      this.error = false;
      BlogPostsApi
        .delete(this.post)
        .then(() => {
          this.success = this.post.status === 'draft' ? 'Draft deleted with success' : 'Post deleted with success';
          this.$router.push({
            name: 'Blog Editor',
          });
        })
        .catch((error) => {
          this.error = this.parseError(error, 'Unable to delete blog post');
        });
    },
    saveBanner() {
      if (!this.file || !this.post.permalink) {
        return;
      }

      return BlogPostsApi
        .updateBanner(this.post, this.file)
        .then(({data}) => {
          this.post = data;
        })
        .catch((error) => {
          this.error = this.parseError(error, 'Unable to update banner');
        })
    },
    getUploader() {
      const self = this;
      return {
        uploadByFile(file) {
          return BlogPostsApi
            .uploadFile(self.post, file)
            .then((response) => {
              console.log(response);
              return {
                success: 1,
                file: {
                  url: response.data,
                },
              };
            });
        },
      };
    },
    initEditorJS() {
      this.editor = new EditorJS({
        data: this.post.content,
        tools: {
          header: Header,
          underline: Underline,
          list: List,
          delimiter: Delimiter,
          embed: Embed,
          image: {
            class: ImageTool,
            config: {
              uploader: this.getUploader(),
            },
          },
          attaches: {
            class: AttachesTool,
            config: {
              uploader: this.getUploader(),
              types: 'application/pdf',
            },
          },
        },
      });
    },
  },
  created() {
    if (!this.$route.query) {
      this.initEditorJS();
      return;
    }

    const permalink = this.$route.params.permalink;
    if (!permalink) {
      this.initEditorJS();
      return;
    }

    this.getBlogPost(permalink);
  },
};
</script>

<style scoped>
  #editorjs,
  .post-content {
    width: 100%;
  }

  .post-content img {
    border-radius: 25px;
    width: 100%;
    margin-bottom: 2em;
  }

  .blog.single > .container {
    margin-top: 50px;
  }

  blockquote {
    margin-bottom: 2em;
  }

  blockquote p {
    margin: 0;
  }

  ul {
    list-style: none;
  }

  .post-details .actions {
    margin-top: 10px;
  }

  .post-details .actions .delete {
    float: left;
  }

  .post-details .actions .save {
    float: right;
  }

  .post-details .actions .save-as-draft {
    margin-right: 10px;
  }

  .small-banner {
    margin-top: 10px;
    color: white;
  }
</style>

<style>
  .header-container {
    margin-bottom: 0 !important;
  }

  .post-content .ce-block {
    margin: 0 0 2em 0;
  }

  .post-content .ce-paragraph {
    font-family: sans-serif;
  }
</style>
